@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
  }

  body {
    @apply bg-x-orange-light text-x-blue-dark;
  }

  :focus-visible {
    @apply outline-none ring ring-blue-300;
  }

  /* modify Elm debug button */
  div[style^="position: fixed; bottom: 2em; right: 2em;"] {
    bottom: 4rem !important;
    right: 0.5rem !important;
  }

  button {
    touch-action: manipulation;
  }
}

@layer utilities {
  /* no utilities yet */
}

@layer components {
  .text-link {
    @apply text-x-orange-base underline hover:text-x-orange-base-emphasis;
  }

  /* impact generator */
  .btn,
  .btn-split {
    @apply bg-orange-600 py-3 px-5 rounded-md w-full transition-colors;
  }
  .btn[disabled],
  .btn.disabled,
  .btn-split[disabled],
  .btn-split.disabled {
    @apply opacity-50 cursor-not-allowed pointer-events-none select-none;
  }
  .btn:hover:not([disabled]),
  .btn-split:hover:not([disabled]) {
    @apply bg-orange-500;
  }
  .btn {
    @apply font-bold;
  }
  .btn-split {
    @apply flex justify-between gap-4;
  }
}

[hidden] {
  display: none;
}

svg {
  width: 100%;
}
