*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

html {
  -webkit-tap-highlight-color: transparent;
}

body {
  --tw-bg-opacity: 1;
  background-color: rgb(255 245 235 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(32 44 85 / var(--tw-text-opacity));
}

:focus-visible {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(147 197 253 / var(--tw-ring-opacity));
  outline: 2px solid #0000;
}

div[style^="position: fixed; bottom: 2em; right: 2em;"] {
  bottom: 4rem !important;
  right: .5rem !important;
}

button {
  touch-action: manipulation;
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.prose {
  color: var(--tw-prose-body);
  max-width: 65ch;
}

.prose :where(p):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.prose :where([class~="lead"]):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-lead);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  font-size: 1.25em;
  line-height: 1.6;
}

.prose :where(a):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-links);
  font-weight: 500;
  text-decoration: underline;
}

.prose :where(strong):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.prose :where(a strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(blockquote strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(thead th strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(ol):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
  list-style-type: decimal;
}

.prose :where(ol[type="A"]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a"]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="A" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="I"]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i"]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="I" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="1"]):not(:where([class~="not-prose"] *)) {
  list-style-type: decimal;
}

.prose :where(ul):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
  list-style-type: disc;
}

.prose :where(ol > li):not(:where([class~="not-prose"] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400;
}

.prose :where(ul > li):not(:where([class~="not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.prose :where(hr):not(:where([class~="not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.prose :where(blockquote):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-quotes);
  border-left-width: .25rem;
  border-left-color: var(--tw-prose-quote-borders);
  quotes: "“""”""‘""’";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
  font-style: italic;
  font-weight: 500;
}

.prose :where(blockquote p:first-of-type):not(:where([class~="not-prose"] *)):before {
  content: open-quote;
}

.prose :where(blockquote p:last-of-type):not(:where([class~="not-prose"] *)):after {
  content: close-quote;
}

.prose :where(h1):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 0;
  margin-bottom: .888889em;
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.11111;
}

.prose :where(h1 strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-weight: 900;
}

.prose :where(h2):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33333;
}

.prose :where(h2 strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-weight: 800;
}

.prose :where(h3):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.6em;
  margin-bottom: .6em;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.6;
}

.prose :where(h3 strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.prose :where(h4):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.5em;
  margin-bottom: .5em;
  font-weight: 600;
  line-height: 1.5;
}

.prose :where(h4 strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.prose :where(img):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(figure > *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose :where(figcaption):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-captions);
  margin-top: .857143em;
  font-size: .875em;
  line-height: 1.42857;
}

.prose :where(code):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-code);
  font-size: .875em;
  font-weight: 600;
}

.prose :where(code):not(:where([class~="not-prose"] *)):before, .prose :where(code):not(:where([class~="not-prose"] *)):after {
  content: "`";
}

.prose :where(a code):not(:where([class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(h1 code):not(:where([class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(h2 code):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-size: .875em;
}

.prose :where(h3 code):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-size: .9em;
}

.prose :where(h4 code):not(:where([class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(blockquote code):not(:where([class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(thead th code):not(:where([class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(pre):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  border-radius: .375rem;
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
  padding: .857143em 1.14286em;
  font-size: .875em;
  font-weight: 400;
  line-height: 1.71429;
  overflow-x: auto;
}

.prose :where(pre code):not(:where([class~="not-prose"] *)) {
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: #0000;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

.prose :where(pre code):not(:where([class~="not-prose"] *)):before, .prose :where(pre code):not(:where([class~="not-prose"] *)):after {
  content: none;
}

.prose :where(table):not(:where([class~="not-prose"] *)) {
  width: 100%;
  table-layout: auto;
  text-align: left;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .875em;
  line-height: 1.71429;
}

.prose :where(thead):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.prose :where(thead th):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  vertical-align: bottom;
  padding-bottom: .571429em;
  padding-left: .571429em;
  padding-right: .571429em;
  font-weight: 600;
}

.prose :where(tbody tr):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.prose :where(tbody tr:last-child):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 0;
}

.prose :where(tbody td):not(:where([class~="not-prose"] *)) {
  vertical-align: baseline;
}

.prose :where(tfoot):not(:where([class~="not-prose"] *)) {
  border-top-width: 1px;
  border-top-color: var(--tw-prose-th-borders);
}

.prose :where(tfoot td):not(:where([class~="not-prose"] *)) {
  vertical-align: top;
}

.prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: #00000080;
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.prose :where(video):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(figure):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(li):not(:where([class~="not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.prose :where(ol > li):not(:where([class~="not-prose"] *)) {
  padding-left: .375em;
}

.prose :where(ul > li):not(:where([class~="not-prose"] *)) {
  padding-left: .375em;
}

.prose :where(.prose > ul > li p):not(:where([class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(.prose > ul > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose :where(.prose > ul > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(.prose > ol > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose :where(.prose > ol > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(hr + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(h2 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(h3 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(h4 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(thead th:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.prose :where(thead th:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.prose :where(tbody td, tfoot td):not(:where([class~="not-prose"] *)) {
  padding: .571429em;
}

.prose :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.prose :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.prose :where(.prose > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(.prose > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0;
}

.text-link {
  --tw-text-opacity: 1;
  color: rgb(255 124 0 / var(--tw-text-opacity));
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.text-link:hover {
  --tw-text-opacity: 1;
  color: rgb(255 150 54 / var(--tw-text-opacity));
}

.btn, .btn-split {
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(234 88 12 / var(--tw-bg-opacity));
  border-radius: .375rem;
  padding: .75rem 1.25rem;
  transition-property: color, background-color, border-color, outline-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.btn[disabled], .btn.disabled, .btn-split[disabled], .btn-split.disabled {
  pointer-events: none;
  cursor: not-allowed;
  -webkit-user-select: none;
  user-select: none;
  opacity: .5;
}

.btn:hover:not([disabled]), .btn-split:hover:not([disabled]) {
  --tw-bg-opacity: 1;
  background-color: rgb(249 115 22 / var(--tw-bg-opacity));
}

.btn {
  font-weight: 700;
}

.btn-split {
  justify-content: space-between;
  gap: 1rem;
  display: flex;
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.top-auto {
  top: auto;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.m-5 {
  margin: 1.25rem;
}

.-my-4 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-14 {
  height: 3.5rem;
}

.h-\[198px\] {
  height: 198px;
}

.h-\[33vh\] {
  height: 33vh;
}

.h-full {
  height: 100%;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-\[198px\] {
  width: 198px;
}

.w-\[200px\] {
  width: 200px;
}

.w-\[25rem\] {
  width: 25rem;
}

.w-full {
  width: 100%;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-\[90vw\] {
  max-width: 90vw;
}

.max-w-full {
  max-width: 100%;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-md {
  max-width: 28rem;
}

.flex-grow {
  flex-grow: 1;
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

.appearance-none {
  -webkit-appearance: none;
  appearance: none;
}

.flex-col {
  flex-direction: column;
}

.place-content-center {
  place-content: center;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-3 {
  gap: .75rem;
}

.gap-5 {
  gap: 1.25rem;
}

.space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.whitespace-pre {
  white-space: pre;
}

.break-all {
  word-break: break-all;
}

.rounded {
  border-radius: .25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-t {
  border-top-width: 1px;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-x-blue-dark {
  --tw-border-opacity: 1;
  border-color: rgb(32 44 85 / var(--tw-border-opacity));
}

.border-x-orange-alt {
  --tw-border-opacity: 1;
  border-color: rgb(255 169 4 / var(--tw-border-opacity));
}

.border-x-orange-base {
  --tw-border-opacity: 1;
  border-color: rgb(255 124 0 / var(--tw-border-opacity));
}

.border-opacity-50 {
  --tw-border-opacity: .5;
}

.bg-inherit {
  background-color: inherit;
}

.bg-orange-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(234 88 12 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-x-blue-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(32 44 85 / var(--tw-bg-opacity));
}

.bg-x-blue-darker {
  --tw-bg-opacity: 1;
  background-color: rgb(17 21 41 / var(--tw-bg-opacity));
}

.bg-x-orange-base {
  --tw-bg-opacity: 1;
  background-color: rgb(255 124 0 / var(--tw-bg-opacity));
}

.bg-x-orange-light {
  --tw-bg-opacity: 1;
  background-color: rgb(255 245 235 / var(--tw-bg-opacity));
}

.bg-x-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.p-10 {
  padding: 2.5rem;
}

.p-3 {
  padding: .75rem;
}

.p-3\.5 {
  padding: .875rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-2\.5 {
  padding-left: .625rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-12 {
  padding-top: 3rem;
}

.text-center {
  text-align: center;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}

.text-orange-500 {
  --tw-text-opacity: 1;
  color: rgb(249 115 22 / var(--tw-text-opacity));
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-x-blue-dark {
  --tw-text-opacity: 1;
  color: rgb(32 44 85 / var(--tw-text-opacity));
}

.text-x-blue-darker {
  --tw-text-opacity: 1;
  color: rgb(17 21 41 / var(--tw-text-opacity));
}

.text-x-orange-base {
  --tw-text-opacity: 1;
  color: rgb(255 124 0 / var(--tw-text-opacity));
}

.text-x-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-opacity-50 {
  --tw-text-opacity: .5;
}

.opacity-50 {
  opacity: .5;
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-colors {
  transition-property: color, background-color, border-color, outline-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.will-change-transform {
  will-change: transform;
}

[hidden] {
  display: none;
}

svg {
  width: 100%;
}

@media (min-width: 640px) {
  .sm\:prose-lg {
    font-size: 1.125rem;
    line-height: 1.77778;
  }

  .sm\:prose-lg :where(p):not(:where([class~="not-prose"] *)) {
    margin-top: 1.33333em;
    margin-bottom: 1.33333em;
  }

  .sm\:prose-lg :where([class~="lead"]):not(:where([class~="not-prose"] *)) {
    margin-top: 1.09091em;
    margin-bottom: 1.09091em;
    font-size: 1.22222em;
    line-height: 1.45455;
  }

  .sm\:prose-lg :where(blockquote):not(:where([class~="not-prose"] *)) {
    margin-top: 1.66667em;
    margin-bottom: 1.66667em;
    padding-left: 1em;
  }

  .sm\:prose-lg :where(h1):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: .833333em;
    font-size: 2.66667em;
    line-height: 1;
  }

  .sm\:prose-lg :where(h2):not(:where([class~="not-prose"] *)) {
    margin-top: 1.86667em;
    margin-bottom: 1.06667em;
    font-size: 1.66667em;
    line-height: 1.33333;
  }

  .sm\:prose-lg :where(h3):not(:where([class~="not-prose"] *)) {
    margin-top: 1.66667em;
    margin-bottom: .666667em;
    font-size: 1.33333em;
    line-height: 1.5;
  }

  .sm\:prose-lg :where(h4):not(:where([class~="not-prose"] *)) {
    margin-top: 1.77778em;
    margin-bottom: .444444em;
    line-height: 1.55556;
  }

  .sm\:prose-lg :where(img):not(:where([class~="not-prose"] *)) {
    margin-top: 1.77778em;
    margin-bottom: 1.77778em;
  }

  .sm\:prose-lg :where(video):not(:where([class~="not-prose"] *)) {
    margin-top: 1.77778em;
    margin-bottom: 1.77778em;
  }

  .sm\:prose-lg :where(figure):not(:where([class~="not-prose"] *)) {
    margin-top: 1.77778em;
    margin-bottom: 1.77778em;
  }

  .sm\:prose-lg :where(figure > *):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: 0;
  }

  .sm\:prose-lg :where(figcaption):not(:where([class~="not-prose"] *)) {
    margin-top: 1em;
    font-size: .888889em;
    line-height: 1.5;
  }

  .sm\:prose-lg :where(code):not(:where([class~="not-prose"] *)) {
    font-size: .888889em;
  }

  .sm\:prose-lg :where(h2 code):not(:where([class~="not-prose"] *)) {
    font-size: .866667em;
  }

  .sm\:prose-lg :where(h3 code):not(:where([class~="not-prose"] *)) {
    font-size: .875em;
  }

  .sm\:prose-lg :where(pre):not(:where([class~="not-prose"] *)) {
    border-radius: .375rem;
    margin-top: 2em;
    margin-bottom: 2em;
    padding: 1em 1.5em;
    font-size: .888889em;
    line-height: 1.75;
  }

  .sm\:prose-lg :where(ol):not(:where([class~="not-prose"] *)) {
    margin-top: 1.33333em;
    margin-bottom: 1.33333em;
    padding-left: 1.55556em;
  }

  .sm\:prose-lg :where(ul):not(:where([class~="not-prose"] *)) {
    margin-top: 1.33333em;
    margin-bottom: 1.33333em;
    padding-left: 1.55556em;
  }

  .sm\:prose-lg :where(li):not(:where([class~="not-prose"] *)) {
    margin-top: .666667em;
    margin-bottom: .666667em;
  }

  .sm\:prose-lg :where(ol > li):not(:where([class~="not-prose"] *)) {
    padding-left: .444444em;
  }

  .sm\:prose-lg :where(ul > li):not(:where([class~="not-prose"] *)) {
    padding-left: .444444em;
  }

  .sm\:prose-lg :where(.sm\:prose-lg > ul > li p):not(:where([class~="not-prose"] *)) {
    margin-top: .888889em;
    margin-bottom: .888889em;
  }

  .sm\:prose-lg :where(.sm\:prose-lg > ul > li > :first-child):not(:where([class~="not-prose"] *)) {
    margin-top: 1.33333em;
  }

  .sm\:prose-lg :where(.sm\:prose-lg > ul > li > :last-child):not(:where([class~="not-prose"] *)) {
    margin-bottom: 1.33333em;
  }

  .sm\:prose-lg :where(.sm\:prose-lg > ol > li > :first-child):not(:where([class~="not-prose"] *)) {
    margin-top: 1.33333em;
  }

  .sm\:prose-lg :where(.sm\:prose-lg > ol > li > :last-child):not(:where([class~="not-prose"] *)) {
    margin-bottom: 1.33333em;
  }

  .sm\:prose-lg :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"] *)) {
    margin-top: .888889em;
    margin-bottom: .888889em;
  }

  .sm\:prose-lg :where(hr):not(:where([class~="not-prose"] *)) {
    margin-top: 3.11111em;
    margin-bottom: 3.11111em;
  }

  .sm\:prose-lg :where(hr + *):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
  }

  .sm\:prose-lg :where(h2 + *):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
  }

  .sm\:prose-lg :where(h3 + *):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
  }

  .sm\:prose-lg :where(h4 + *):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
  }

  .sm\:prose-lg :where(table):not(:where([class~="not-prose"] *)) {
    font-size: .888889em;
    line-height: 1.5;
  }

  .sm\:prose-lg :where(thead th):not(:where([class~="not-prose"] *)) {
    padding-bottom: .75em;
    padding-left: .75em;
    padding-right: .75em;
  }

  .sm\:prose-lg :where(thead th:first-child):not(:where([class~="not-prose"] *)) {
    padding-left: 0;
  }

  .sm\:prose-lg :where(thead th:last-child):not(:where([class~="not-prose"] *)) {
    padding-right: 0;
  }

  .sm\:prose-lg :where(tbody td, tfoot td):not(:where([class~="not-prose"] *)) {
    padding: .75em;
  }

  .sm\:prose-lg :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"] *)) {
    padding-left: 0;
  }

  .sm\:prose-lg :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"] *)) {
    padding-right: 0;
  }

  .sm\:prose-lg :where(.sm\:prose-lg > :first-child):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
  }

  .sm\:prose-lg :where(.sm\:prose-lg > :last-child):not(:where([class~="not-prose"] *)) {
    margin-bottom: 0;
  }
}

.placeholder\:text-x-orange-base-emphasis::placeholder {
  --tw-text-opacity: 1;
  color: rgb(255 150 54 / var(--tw-text-opacity));
}

.placeholder\:text-opacity-60::placeholder {
  --tw-text-opacity: .6;
}

.hover\:border-x-orange-base-emphasis:hover {
  --tw-border-opacity: 1;
  border-color: rgb(255 150 54 / var(--tw-border-opacity));
}

.hover\:bg-x-orange-base-emphasis:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 150 54 / var(--tw-bg-opacity));
}

.disabled\:border-x-gray-base:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(184 184 184 / var(--tw-border-opacity));
}

.disabled\:bg-x-gray-base:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(184 184 184 / var(--tw-bg-opacity));
}

.disabled\:bg-x-gray-light:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(224 224 224 / var(--tw-bg-opacity));
}

.disabled\:text-x-gray-dark:disabled {
  --tw-text-opacity: 1;
  color: rgb(104 104 104 / var(--tw-text-opacity));
}

.aria-hidden\:opacity-0[aria-hidden="true"] {
  opacity: 0;
}

.prose-a\:text-x-orange-base :where(a):not(:where([class~="not-prose"] *)) {
  --tw-text-opacity: 1;
  color: rgb(255 124 0 / var(--tw-text-opacity));
}

.prose-a\:no-underline :where(a):not(:where([class~="not-prose"] *)) {
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
}

@media (min-width: 640px) {
  .sm\:inline {
    display: inline;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:w-\[160px\] {
    width: 160px;
  }

  .sm\:p-8 {
    padding: 2rem;
  }

  .sm\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm\:py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .sm\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .lg\:max-w-4xl {
    max-width: 56rem;
  }

  .lg\:p-12 {
    padding: 3rem;
  }

  .lg\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

/*# sourceMappingURL=index.dc3c93d4.css.map */
